
/* eslint-disable */
import { defineComponent, reactive } from "vue";
import DataTitle from "@/components/DataTitle.vue";
import DataBoxes from "@/components/DataBoxes.vue";
import CountrySelect from "@/components/CountrySelect.vue";

const loadingImage = require("@/assets/earth.svg");

export default defineComponent({
  components: { DataTitle, DataBoxes, CountrySelect },

  setup() {
    const states = reactive({
      loading: true, //
      title: "Global", //
      dataDate: "", //
      stats: {}, //
      countries: [],
      loadingImage
    });

    const fetchCovidData = async () => {
      const res = await fetch("https://api.covid19api.com/summary");
      const data = res.json();

      return data;
    };

    const getCountryData = (country: any) => {
      states.stats = country;
      states.title = country.Country;
    };

    fetchCovidData().then(data => {
      states.dataDate = data.Date;
      states.stats = data.Global;
      states.countries = data.Countries;
      states.loading = false;
    });

    return { states, getCountryData };
  }
});
