
/* eslint-disable */
import { defineComponent, computed } from "vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "DataTitle",
  props: {
    text: String, //
    dataDate: Date
  },

  setup(props) {
    const timestamp = computed(() => dayjs(props.dataDate).format("DD-MM-YYYY HH:mm:ss"));

    return {
      timestamp
    };
  }
});
