
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DataBoxes',

  props: {
    stats: Object,
  },

  setup() {
    const numberWithCommas = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return {
      numberWithCommas,
    };
  },
});
