
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'CountrySelect',

  props: {
    countries: Array,
  },

  setup(props, { emit }) {
    const selected = ref(0);
    const onChange = () => {
      const country = props.countries?.find((item: any) => item.ID === selected.value);

      emit('get-country', country);
    };

    return {
      selected,
      onChange,
    };
  },
});
